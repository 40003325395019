export const environment = {
  production: true,
  backendUrl: "https://pingmoney.co/backend/",
  // backendUrl: "http://admin.pingmoney.prod/",
  // backendUrl: "https://dev.pingmoney.co/backend/",
  // backendUrl: "http://admin.pingmoney.conf/",
  // apiUrl: "https://prod-uat.pingmoney.co/backend/api/v1",
  // apiUrl: "http://admin.pingmoney.prod/api/v1",
  // apiUrl: "https://dev.pingmoney.co/backend/api/v1",
  apiUrl: "https://pingmoney.co/backend/api/v1",
  // appUrl: "https://prod-uat.pingmoney.co/",
  // appUrl: "http://localhost:4200/",
  // appUrl: "https://dev.pingmoney.co/",
  appUrl: "https://pingmoney.co/",
  // assetUrl: "https://prod-uat.pingmoney.co/assets/",
  // assetUrl: "http://localhost:4200/assets/",
  // assetUrl: "https://dev.pingmoney.co/assets/",
  assetUrl: "https://pingmoney.co/assets/",
  // siteReference: "test_paythreesixfive73197",
  siteReference: "paythreesixfive73198", // production
  stActionUrl: "https://payments.securetrading.net/process/payments/details",
  stApiKey: "v7qQXOQIqda8tnszIAJAP33AepLJT8hn",
  acquiredActionUrl: "https://hpp.acquired.com",
  // acquiredCompanyId: "9f6e57d9-273f-052f-ba74-518ad3dddaf7", // Todo: to change to this version, once we retire acquired v1
  // acquiredCompanyId: "019016e3-ce45-700f-b2a2-23035da2c40b",
  // acquiredCompanyId: "401",

  acquiredCompanyId: "327", // production
  // acquiredCompanyId: "1255",

  acquiredMid: "2096", // production
  // acquiredMid: "1522",

  templateId: "1182", // production
  // templateId: "1441",

  acquiredMidEur: "2223", // production
  // acquiredMidEur: "2214",

  templateIdEur: "1589", // production
  // templateIdEur: "1723",

  // acquiredCompanyHash: "zCuatbc0",
  acquiredCompanyHash: "kqSuMT9j", // production
  // acquiredCompanyHash: "LKywyeVf",

  acquiredApiKey: "6p0O7HApp0ArDMAP47is", // production
  // acquiredApiKey: "7132c76844a1f24c9c049877fe94ae09",

  veriffApiKey: "0a296523-5242-4a88-a790-9f5b7bc113d9",
  applePayMerchantIdentifier: "merchant.co.pingmoney.pay365",
  applePayInitiativeContext: "pingmoney.co",
  applePaydisplayName: "Ping Money",
  initVolumeEnvironment: "LIVE", // LIVE, SANDBOX
  // initVolumeEnvironment: "SANDBOX", // LIVE, SANDBOX
  trueLayerProduction: true, // true, false
};
